<template>
  <div class="m">
    <newTitle type="nav" :txt="title"></newTitle>
    <!-- 轮播图 -->
    <div class="detali-box-carousel">
      <el-carousel
        :autoplay="false"
        :interval="3000"
        arrow="always"
        ref="slideCarousel"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <!-- {{ item }} -->
          <el-image
            @click="bannerItemBtnFn(item.jump_url)"
            fit="cover"
            class="detali-box-carousel-img"
            :src="item.image"
          />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 分类跳转 -->
    <div class="cfToBox">
      <div class="cfToBox-item" @click="itemBtnFn(`/HowtoUse?id=${id}`)">
        <img src="../../assets/images/havefun/howtouse.png" alt="" />
        <div v-html="$t('How to Use')" class="cfToBox-item-txt box1"></div>
      </div>
      <div class="cfToBox-item" @click="itemBtnFn(`/TravelTips?id=${id}`)">
        <img src="../../assets/images/havefun/TravelTips.png" alt="" />
        <div v-html="$t('Travel Tips')" class="cfToBox-item-txt box2"></div>
      </div>
      <div
        class="cfToBox-item"
        @click="itemBtnFn(`/hfPage_list?id=${id}&l=${lanPam}`)"
      >
        <img src="../../assets/images/havefun/Facility.png" alt="" />
        <div v-html="$t('Facility List')" class="cfToBox-item-txt box3"></div>
      </div>
      <div class="cfToBox-item" @click="itemBtnFn(`/ReferenceLink?id=${id}`)">
        <img src="../../assets/images/havefun/ReferenceLink.png" alt="" />
        <div v-html="$t('Reference Link')" class="cfToBox-item-txt box4"></div>
      </div>
    </div>

    <!-- 底部 -->
    <CBottom :lanPam="lanPam"></CBottom>
    <!-- <div class="btmBox">
      <p>Privacy Policy</p>
      - Copyright © Tripellet.com , All rights reserved -
    </div> -->
  </div>
</template>

<script>
import { reloadMessage } from "@/lang";
import { homeLanList } from "@/utils/public_data";
import newTitle from "./Component_title.vue";
import { getProductListTop } from "@/api/hfIndex";
import CBottom from "./Component_bottom.vue";
export default {
  data() {
    return {
      title:'',
      id: "",
      bannerList: [],
      logo: require("@/assets/images/customer/MB-home.png"),
      lanLogo: require("@/assets/images/customer/PC_line.png"),
      lanList: homeLanList(),
      showPopover: false,
      screenWidth: document.body.clientWidth,
      actions: [
        { text: "日本語", lan: "ja" },
        { text: "简体中文", lan: "zh" },
        { text: "繁體中文", lan: "zh_tw" },
        { text: "English", lan: "en" },
        { text: "한국어", lan: "ko" },
      ],
      lanListPam: {
        zh: "sc",
        zh_tw: "tc",
        en: "en",
        ja: "jp",
        ko: "kr",
      },
    };
  },
  activated() {
    this.id = this.$route.query.id;
    this.getProductListTopFn(this.id);
  },
  mounted() {
    this.slideBanner();
    // document.querySelector('.nav-right').style.marginLeft = `${this.screenWidth - this.screenWidth * 0.69}px`
  },
  components: { newTitle, CBottom },
  computed: {
    lanPam() {
      return this.lanListPam[localStorage.getItem("locale")] || "tc";
    },
  },
  methods: {
    bannerItemBtnFn(e) {
      // window.location.href(你的外部链接地址);
      if (!e) return;
      window.open(e, "_blank");
    },
    async getProductListTopFn(id) {
      let res = await getProductListTop({ lang: this.lanPam, id: this.id });
      console.log("1111", res.data.data);
      this.bannerList = res.data.data.banners;
      this.title = res.data.data.title;
    },
    itemBtnFn(e) {
      this.$router.push(e);
    },
    // 滑动切换
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector(".el-carousel__container");
      var startPointX = 0;
      var stopPointX = 0;
      //重置坐标
      var resetPoint = function () {
        startPointX = 0;
        stopPointX = 0;
      };
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPointX = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPointX = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener("touchend", function (e) {
        if (stopPointX == 0 || startPointX - stopPointX == 0) {
          resetPoint();
          return;
        }
        if (startPointX - stopPointX > 0) {
          resetPoint();
          that.$refs.slideCarousel.next();
          return;
        }
        if (startPointX - stopPointX < 0) {
          resetPoint();
          that.$refs.slideCarousel.prev();
          return;
        }
      });
    },

    pageHandle(path, params) {
      if (params) {
        this.$router.push({ path, query: { search: JSON.stringify(params) } });
      } else {
        this.$router.push(path);
      }
    },
    onSelect(action) {
      console.log(action);
      this.$i18n.locale = action.lan;
      localStorage.setItem("locale", action.lan);
      reloadMessage();
      this.$router.go(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.m {
  height: 100vh;
  max-width: 766px;
  margin: 0 auto;
}
.titleBox {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  box-shadow: 0 3px 6px #a8a8a845;
  .el-icon-arrow-left {
    font-size: 22px;
    position: absolute;
    left: 16px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .tit_Txt {
    height: 100%;
    font-size: 14px;
    color: #000;
  }
}

// 走马灯样式
.detali-box-carousel {
  height: 62.4vw;
  width: 100%;
  .detali-box-carousel-img {
    width: 100%;
    height: 100%;
  }
}
/deep/.el-carousel__container {
  // height: 322px;

  .el-carousel__item {
    // background-color: aqua;
  }
}
/deep/.el-carousel__arrow {
  //   background-color: rgba(255, 255, 255, 0.5);
  display: none;
}
/deep/.el-carousel {
  // overflow: unset;
  height: 100%;
  .el-carousel__container {
    height: 56.5333vw;
  }
  .el-carousel__indicators {
    bottom: 1.3333vw;
    .el-carousel__indicator {
      padding: 0.8vw;

      .el-carousel__button {
        width: 1.6vw;
        height: 1.6vw;
        border-radius: 50%;
        background-color: #de6460;
      }
    }
  }
}

.cfToBox {
  padding: 0 16px;
  .cfToBox-item {
    position: relative;
    // height: 122.5px;
    margin-bottom: 12px;
    filter: drop-shadow(0 3px 4px #00000029);
    border-radius: 6px;
    .cfToBox-item-txt {
      white-space: pre-wrap;
      font-weight: 700;
      // font-size: 22px;
      font-size: 6vw;
      position: absolute;
      left: 8.5333vw;
      top: 50%;
      transform: translateY(-50%);
      &.box1 {
        color: #e38079;
      }
      &.box2 {
        color: #71ad4b;
      }
      &.box3 {
        color: #22a1df;
      }
      &.box4 {
        color: #d79000;
      }
    }
    img {
      width: 100%;
    }
  }
}

.btmBox {
  margin: 0 auto;
  max-width: 766px;
  text-align: center;
  background-color: #e5e5e5;
  padding: 5.3333vw 0 7.7333vw;
  font-size: 2.6667vw;
  p {
    margin-bottom: 0.5333vw;
  }
}

@media (min-width: 766px) {
  .detali-box-carousel {
    height: 468px;
  }
  /deep/.el-carousel {
    .el-carousel__container {
      height: 424px;
    }
    .el-carousel__indicators {
      bottom: 10px;
      .el-carousel__indicator {
        padding: 6px;

        .el-carousel__button {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .btmBox {
    padding: 40px 0 58px;
    font-size: 20px;
    p {
      margin-bottom: 4px;
    }
  }

  .cfToBox {
    .cfToBox-item {
      .cfToBox-item-txt {
        font-size: 45px;
        left: 64px;
      }
    }
  }
}
</style>
